import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Rules = () => {
  return (
    <>
      <div className="rules-parent-container">
        <div className="rules-parent-container-header"></div>
        <div className="rules-inside-container">
          <ul className="table-of-content">
            <li>
              <a href="#1">ტერმინთა განმარტება</a>
            </li>
            <li>
              <a href="#2">რეგისტრაცია</a>
            </li>
            <li>
              <a href="#3">მომხმარებელი ადასტურებს და იძლევა გარანტიას</a>
            </li>
            <li>
              <a href="#4">
                მომხმარებლის მიერ შეკვეთის გაფორმება და პროდუქციის შეძენა
              </a>
            </li>
            <li>
              <a href="#5">შეძენილი პროდუქციის მიწოდების წესები და პირობები</a>
            </li>
            <li>
              <a href="#6">მომსახურების მიღება</a>
            </li>
            <li>
              <a href="#7">ანგარიშსწორების წესი</a>
            </li>
            <li>
              <a href="#8">პირადი ანგარიშის დახურვა და სარგებლობის შეზღუდვა</a>
            </li>
            <li>
              <a href="#9">პასუხისმგებლობის შეზღუდვა</a>
            </li>
            <li>
              <a href="#10">შეზღუდვები ვებგვერდის გამოყენებისას</a>
            </li>
            <li>
              <a href="#11">მარკეტინგი</a>
            </li>
            <li>
              <a href="#12">საავტორო უფლებები</a>
            </li>
            <li>
              <a href="#13">მოქმედების ვადა და შეწყვეტა</a>
            </li>
            <li>
              <a href="#14">მესამე მხარე</a>
            </li>
            <li>
              <a href="#15">
                მარეგულირებელი კანონმდებლობა და დავების გადაწყვეტის წესი
              </a>
            </li>
            <li>
              <a href="#16">ცვლილებები და დამატებები</a>
            </li>
          </ul>

          <div className="editor">
            <h2>წესები და პირობები</h2>
            <div className="read-rules">
              <p>
                წინამდებარე დოკუმენტი არის Pethub-ის ვებგვერდით სარგებლობის
                წესები და პირობები.
              </p>
              <p>
                წინამდებარე დოკუმენტი არის ორმხრივად მავალდებულებელი
                ხელშეკრულება, რომელიც ფორმდება მომხმარებელსა და შპს „ფეთჰაბ
                პლიუსი“ შორის. შპს „ფეთჰაბ პლიუსი“ (ს.ნ. 405210374)
                რეგისტრირებული მისამართი - ქ. თბილისი, საბურთალოს რაიონი,
                ვაჟა-ფშაველას გამზირი N30ა, ბ. 37.
              </p>
              <p>
                შპს „ფეთჰაბ პლიუსი“ წინამდებარე სარგებლობის წესებსა და
                პირობებში, კონფიდენციალურობის პოლიტიკაში და ყველა სხვა
                სახელმძღვანელოსა და ინსტრუქციაში შემდგომში მოხსენიებული იქნება
                როგორც „Pethub”.
              </p>
              <p>
                Pethub-ის ვებგვერდით სარგებლობის წესები და პირობები, მასში
                შეტანილ ცვლილებებთან და Pethub-ის სხვა რეგულაციებთან ერთად
                წარმოადგენენ ერთიან დოკუმენტს და განხილულ უნდა იქნეს როგორც
                მომხმარებელთან გაფორმებული ერთიანი ხელშეკრულება.
              </p>
              <p>
                ვებგვერდზე რეგისტრაციის დროს მომხმარებელი ეთანხმება და აღიარებს
                წინამდებარე წესებსა და პირობებს, კონფიდენციალურობის პოლიტიკას,
                დაბრუნებისა და გაცვლის წესებს, ასევე აცხადებს თანხმობას,
                გავრცელდეს მასზე მათი სამართლებრივი ძალა.
              </p>
              <p>
                იმ შემთხვევაში, თუ მომხმარებელი არ ეთანხმება Pethub-ის
                სამართლებრივი დოკუმენტების შინაარსს, შეუძლია არ გამოიყენოს
                ვებგვერდი, თუმცა, ამასთან Pethub-ი მომხმარებელს მიმართავს
                თხოვნით, შენიშვნები მიაწოდოს ელექტრონულ ფოსტაზე.
              </p>
              <p>
                დამატებითი კითხვების, პრეტენზიის, თხოვნის შემთხვევაში,
                მომხმარებელს შეუძლია Pethub-ის დაუკავშირდეს ელექტრონული ფოსტის
                მეშვეობით. Pethub-ის გუნდი მზად არის მომხმარებელს კითხვებზე
                გასცეს დეტალური პასუხი.
              </p>

              <br />

              <h3>ტერმინთა განმარტება</h3>
              <p>
                წინამდებარე წესებსა და პირობებში გამოყენებულ ტერმინებს გააჩნიათ
                შემდეგი მნიშვნელობა:
              </p>
              <ul>
                <li>
                  <b>Pethub </b>(შემდგომში „ჩვენ“, „ჩვენი“, „ვებ-გვერდი“) -
                  საქართველოს კანონმდებლობის შესაბამისად დაფუძნებული შპს „ფეთჰაბ
                  პლიუსი“, საიდენტიფიკაციო კოდი 405210374, რეგისტრირებული: ქ.
                  თბილისი, საბურთალოს რაიონი, ვაჟა-ფშაველას გამზირი N30ა, ბ. 37.
                </li>
                <li>
                  <b>მომხმარებელი </b>- იურიდიული პირი ან 18 წელს მიღწეული
                  (ქმედუნარიანი) ფიზიკური პირი, რომელსაც სურს შეიძინოს Pethub-ზე
                  განთავსებული პროდუქცია.
                </li>
                <li>
                  <b>მომხმარებლის თანხმობა </b>- წინამდებარე დოკუმენტის
                  მომხმარებლის მიერ გაცნობისა და მისი პერსონალური ხასიათის
                  მონაცემების დამუშავების შესახებ გამოხატული ნება.
                </li>
                <li>
                  <b>მესამე პირი </b>- ნებისმიერი ფიზიკური ან იურიდიული პირი,
                  საჯარო დაწესებულება.
                </li>
                <li>
                  <b>პირდაპირი მარკეტინგი </b>- ტელეფონის, ფოსტის, ელექტრონული
                  ფოსტის ან სხვა ელექტრონული საშუალებით მონაცემთა სუბიექტისთვის
                  ინფორმაციის პირდაპირი და უშუალო მიწოდება ფიზიკური პირის ან/და
                  იურიდიული პირის, საქონლის, იდეის, მომსახურების, სამუშაოს ან/და
                  წამოწყების, აგრეთვე საიმიჯო და სოციალური თემატიკისადმი
                  ინტერესის ფორმირების, შენარჩუნების, რეალიზაციის ან/და
                  მხარდაჭერის მიზნით.
                </li>
                <li>
                  <b>რეგისტრაცია </b>- პირადი ანგარიშის შექმნა.
                </li>
                <li>
                  <b>ანგარიში </b>- მომხმარებლის მიერ Pethub-ზე რეგისტრირებული
                  პირადი გვერდი.
                </li>
                <li>
                  <b></b>
                </li>
                <li>
                  <b></b>
                </li>
              </ul>
              <p>
                ვებგვერდზე ანგარიშის გახსნით მომხმარებელს ეძლევა საშუალება,
                შეიძინოს Pethub-ზე განთავსებული პროდუქცია.
              </p>
              <p>
                მომხმარებლის მიერ გაკეთებული თითოეული შეკვეთა წარმოადგენს
                ცალკეულ ნასყიდობის ხელშეკრულებას, რომლის მხარეები არიან
                ვებგვერდის ავტორიზებული მომხმარებელი და შპს „ფეთჰაბ პლიუსი“.
                შეკვეთის გაფორმებისას გენერირდება ნასყიდობის საგანი, მისი
                ღირებულება და მიწოდების ადგილი.
              </p>

              <br />

              <h3>რეგისტრაცია</h3>
              <p>
                Pethub-ზე პროდუქციის შესაძენად საჭიროა ვებგვერდზე რეგისტრაცია,
                რისთვისაც აუცილებელია სარეგისტრაციო ფორმის შევსება შემდეგი
                მონაცემების მითითებით:
              </p>
              <ul>
                <li>სახელი</li>
                <li>გვარი</li>
                <li>ელექტრონული ფოსტა</li>
                <li>საკონტაქტო ტელეფონის ნომერი</li>
                <li>საცხოვრებელი მისამართი</li>
                <li>სისტემაში შესასვლელი პაროლი</li>
              </ul>
              <p>
                მომხმარებელს რეგისტრაციის გავლა ვებგვერდზე შეუძლია პროდუქტის
                შეძენის პროცესში და ასევე შეძენამდეც.
              </p>
              <p>
                შეძენამდე რეგისტრაცია/ავტორიზაცია ხდება მას მერე რაც
                მომხმარებელი ვებგვერდის მთავარი მენიუდან აირჩევს „სისტემაში
                შესვლას“.
              </p>
              <p>სისტემაში შესვლა/ავტორიზაცია შესაძლებელია შემდეგი გზებით:</p>
              <ul>
                <li>სოციალური ქსელით</li>
                <li>Google-ით</li>
                <li>ელექტრონული ფოსტით</li>
              </ul>

              <br />

              <h3>მომხმარებელი ადასტურებს და იძლევა გარანტიას, რომ:</h3>
              <ul>
                <li>მის მიერ მითითებული ინფორმაცია არის სრული და სწორი;</li>
                <li>
                  იგი არის 18 წელს მიღწეული, სრულწლოვანი ქმედუნარიანი პირი;
                </li>
                <li>
                  არ გამოიყენებს სხვა პირის მაიდენტიფიცირებელ ინფორმაციას
                  Pethub-ის ვებგვერდზე რეგისტრაციის მიზნით;
                </li>
                <li>
                  არ გამოიყენებს სახელს, რომელიც შესაძლოა დამამცირებელი და
                  შეურაცხმყოფელი აღმოჩნდეს ნებისმიერი სხვა პირისათვის;
                </li>
                <li>
                  არ გამოიყენებს ვებგვერდს ყალბი ინფორმაციის გავრცელების მიზნით;
                </li>
                <li>
                  არ გამოიყენებს ვებგვერდს სხვა კომპანიის, კომპანიის
                  თანამშრომლის ან ნებისმიერი სხვა პირის განსახიერების მიზნით;
                </li>
                <li>
                  არ დაარღვევს სხვა პირის უფლებებს, კანონსაწინააღმდეგო და ზიანის
                  მიმყენებელი ქმედებით;
                </li>
                <li>
                  არ გამოიყენებს მოწყობილობას, პროგრამას, რომლის მიზანი არის
                  ინფორმაციის მოპოვება ვებგვერდიდან ან/და ნორმალური
                  ფუნქციონირებისათვის ხელის შეშლა;
                </li>
                <li>
                  დაიცავს საავტორო და მომიჯნავე უფლებებს, არ განახორციელებს
                  Pethub-ი-ზე არსებული ინფორმაციის: ლოგო, პიქტოგრამა, სურათი,
                  გრაფიკა, გამოსახულება, პატენტი, სასაქონლო ნიშნები, დიზაინის და
                  სხვა ნებისმიერი სახის ინტელექტუალური საკუთრების უნებართვო
                  გამოყენებას, დამუშავებასა და გამოქვეყნებას, Pethub-ის
                  წინასწარი წერილობითი თანხმობის გარეშე;
                </li>
                <li>
                  დაიცავს საავტორო და მომიჯნავე უფლებებს, არ განახორციელებს
                  Pethub-ი-ზე არსებული ინფორმაციის: ლოგო, პიქტოგრამა, სურათი,
                  გრაფიკა, გამოსახულება, პატენტი, სასაქონლო ნიშნები, დიზაინის და
                  სხვა ნებისმიერი სახის ინტელექტუალური საკუთრების უნებართვო
                  გამოყენებას, დამუშავებასა და გამოქვეყნებას, Pethub-ის
                  წინასწარი წერილობითი თანხმობის გარეშე;
                </li>
                <li>
                  არ შეეცდება მოიპოვოს წვდომა სხვა მომხმარებლის ავტორიზაციის
                  ინფორმაციაზე;
                </li>
                <li>
                  არ განახორციელებს ისეთ ქმედებას, რომელიც საფრთხეს შეუქმნის
                  Pethub-ის გამართულ ფუნქციონირებას;
                </li>
                <li>
                  არ განახორციელებს ქმედებას, რომელიც იკრძალება მოქმედი
                  კანონმდებლობით, წინამდებარე ხელშეკრულებითა და Pethub-ის
                  კონფიდენციალურობის დაცვის პოლიტიკით.
                </li>
              </ul>

              <br />

              <h3>მომხმარებლის მიერ შეკვეთის გაფორმება და პროდუქციის შეძენა</h3>
              <p>
                მომხმარებლის მიერ პროდუქტის შერჩევის და ვირტუალურ კალათაში
                მოთავსების შემდგომ, ახალი მომხმარებლის შემთხვევაში საჭირო იქნება
                შემდეგი ინფორმაციის მითითება: ელექტრონული ფოსტა, სახელი,
                მობილურის ნომერი და მისამართი სადაც სურს რომ შენაძენი მიიღოს.
              </p>
              <p>
                Pethub-ის პროდუქციის საფასურის გადახდის მიზნით გადამისამართდება
                ანგარიშსწორების გვერდზე. ანგარიშსწორების განხორციელებამდე
                მომხმარებელი ვალდებულია გადაამოწმოს მის მიერ მითითებული
                მისამართი და საკონტაქტო ინფორმაცია, შეძენილი პროდუქციის მისთვის
                შეუფერხებლად მიწოდების განხორციელების მიზნით. არასწორი/არასრული
                მისამართისა ან/და საკონტაქტო ინფორმაციის მითითების შემთხვევაში
                პროდუქციის მიწოდების შეფერხების თაობაზე პასუხისმგებლობა ეკისრება
                მომხმარებელს.
              </p>
              <p>
                მომხმარებელი მის მიერ შეძენილი პროდუქტის შესახებ ინფორმაციის
                გადამოწმებას შეძლებს მის პირად გვერდზე.
              </p>
              <p>
                Pethub-ი იტოვებს უფლებამოსილებას უარი თქვას ან გააუქმოს
                მომხმარებლის შეკვეთა თაღლითობის, უნებართვო ან უკანონო
                ტრანზაქციაზე ეჭვის არსებობის შემთხვევაში.
              </p>
              <p>
                მომხმარებლის მიერ პროდუქტის საფასურის გადახდის შემდგომ შეძენილ
                ნივთზე საკუთრების უფლება სრულად გადადის მომხმარებელზე.
              </p>
              <p>
                შეძენილი პროდუქციის გაცვლისა და დაბრუნების წესები მომხმარებელს
                შეუძლია იხილოს{" "}
                <Link style={{ color: "orange" }} to="../returnpolicy">
                  დაბრუნების პირობების გვერდზე .
                </Link>
              </p>

              <br />

              <h3>შეძენილი პროდუქციის მიწოდების წესები და პირობები</h3>
              <p>
                მომხმარებელი აცხადებს თანხმობას, რომ მის მიერ შეძენილი
                პროდუქციის მიწოდებას განახორციელებს შპს „ფეთჰაბ პლიუსი“
                (საიდენტიფიკაციო კოდი: 405210374, რეგისტრირებული: საქართველო,
                თბილისი, საბურთალოს რაიონი, ს. ვაჟა-ფშაველას გამზირი N30ა, ბ.
                N37).
              </p>
              <p>
                მიწოდების ხელშეკრულების მხარეები არიან მომხმარებელი და შპს
                „ფეთჰაბ პლიუსი“.
              </p>
              <p>
                Pethub, საკურიერო მომსახურების გაწევის მიზნით, მომხმარებლის
                შესახებ მისთვის ცნობილ, პერსონალური ხასიათის კონფიდენციალურ
                ინფორმაციას, კერძოდ, მომხმარებლის სახელს, გვარს, მისამართსა და
                ტელეფონის ნომერს მიაწოდებს საკურიერო კომპანიას (ასეთის არსებობის
                შემთხვევაში).
              </p>
              {/* <p>
                Pethub მომხმარებლის მიერ შეძენილ პროდუქციას გადასცემს საკურიერო
                კომპანია შპს „kompaniis saxeli“, რომელიც ახორციელებს პროდუქციის
                ტრანსპორტირებას მითითებულ მისამართზე. Pethub-ის პასუხისმგებლობა
                სრულდება პროდუქციის საკურიერო კომპანიისათვის გადაცემის დროს.
                პროდუქციის დაკარგვისა და დაზიანების რისკი სრულად გადადის
                საკურიერო მომსახურების გამწევ კომპანიაზე.
              </p> */}
              <p>
                კონფიდენციალური ინფორმაციის დამუშავებისა და შენახვის შესახებ
                პოლიტიკა მომხმარებელს შეუძლია იხილოს{" "}
                <Link style={{ color: "orange" }} to="../privatepolicy">
                  კონფიდენციალური პოლიტიკის გვერდზე.
                </Link>
              </p>

              <br />

              <h3>მომსახურების მიღება</h3>
              <p>
                შეძენილი პროდუქციის მიღება მომხმარებელს შეუძლია როგორც სამუშაო,
                ასევე არასამუშაო დღეებში.
              </p>
              <p>
                Pethub-ის ვებგვერდიდან შეძენეილი პროდუქციის მიწოდება ხდება,
                მომხმარებლის მიერი პროდუქციის შეძენიდან 4 კალენდარულ დღეში,
                მხოლოდ თბილისის ტერიტორიაზე.
              </p>
              <p>
                დიდსა და პატარა რეგიონებში, Pethub-ის ვებგვერდიდან შეძენილი
                პროდუქციის მიწოდება ხდება 4 - 7 სამუშაო დღეში მხოლოდ იმ
                რეგიონებში სადაც ფუნქციონირებს საქართველოს ფოსტა.
              </p>
              <p>საკურიერო მომსახურება თბილისის მასშტაბით უფასოა.</p>

              <br />

              <h3>ანგარიშსწორების წესი</h3>
              <p>
                ანგარიშსწორება ხორციელდება მხოლოდ უნაღდო ანგარიშსწორების გზით.
              </p>
              <p>
                შეკვეთის გაფორმებისას მომხმარებელი ვებგვერდიდან გადამისამართდება
                ანგარიშსწორების გვერდზე, სადაც უთითებს საკუთარი ბარათის
                მონაცემებს. ანგარიშსწორების მოთხოვნა მუშავდება და დასტურდება
                ბანკის მიერ.
              </p>
              <p>
                Pethub-ს არ აქვს წვდომა როგორც ანგარიშსწორების გვერდზე, ასევე
                მომხმარებლის მიერ მითითებულ საბანკო ბარათის მონაცემებზე.
              </p>
              <p>
                ანგარიშსწორების წარმატებით დასრულების შემთხვევაში, ეკრანზე
                გამოდის შესაბამისი ინფორმაციის შემცველი შეტყობინება.
              </p>

              <br />

              <h3>პირადი ანგარიშის დახურვა და სარგებლობის შეზღუდვა</h3>
              <p>
                თუ Pethub მიიჩნევს, რომ მომხმარებლის მხრიდან დაირღვა წინამდებარე
                წესები და პირობები ან მომხმარებელი რეგისტრირებულია ყალბი
                ინფორმაციის მითითებით, იგი უფლებამოსილია დახუროს წვდომა და
                შეზღუდოს პირადი ანგარიშით სარგებლობა ცალმხრივად ელექტრონულ
                ფოსტაზე გაგზავნილი შეტყობინების საფუძველზე. მომხმარებელი მსგავსი
                ტიპის შეტყობინების მიღების შემთხვევაში უნდა დაუკავშირდეს
                Pethub-ის გუნდს.
              </p>
              {/* <p>
                მომხმარებლის მხრიდან Pethub-ის ვებგვერდზე მისი პირადი
                ანგარიშის გაუქმების/დახურვისათვის მან პირად ანგარიშში
                პარამეტრების ველში შესვლის შემდეგ უნდა დააჭიროს “ანგარიშის
                წაშლა”-ს, რის შემდგომაც მისი პირადი ანგარიში ავტომატურად
                იხურება.
              </p> */}

              <br />

              <h3>პასუხისმგებლობის შეზღუდვა</h3>
              <p>
                Pethub-ი შესაძლებლობების ფარგლებში მაქსიმალურად უზრუნველყოფს
                თავდაცვის შესაბამისი მექანიზმების შემუშავებას, თუმცა არ არის
                პასუხისმგებელი პლატფორმაზე დაცული მონაცემების არასანქცირებულ
                წვდომაზე, მესამე პირების მიერ მიყენებულ ზიანზე, მათ მიერ
                მომხმარებლისათვის შეთავაზებულ მომსახურებაზე.
              </p>
              <p>
                მომხმარებელი ვებგვერდზე რეგისტრაციით ადასტურებს, რომ არის
                ქმედუნარიანი 18 წელს მიღწეული პირი და ვებგვერდით სარგებლობს
                საკუთარი თავისუფალი ნებითა და რისკით.
              </p>
              <p>
                Pethub-ი პასუხისმგებელი არ არის, რომ ვებგვერდი ან მომსახურება
                მომხმარებლისათვის იქნება მუდმივად ხელმისაწვდომი და არ იძლევა
                გარანტიას, რომ ვებგვერდს არ ექნება პროგრამული ხარვეზები,
                ვირუსები ან/და სხვა ნებისმიერი შეცდომა.
              </p>
              <p>
                Pethub-ი იხნის სრულ პასუხისმგებლობას გაუფრთხილებლობის შედეგად
                დამდგარ ზიანზე, ასევე იმ შემთხვევებზე, როდესაც მესამე პირისათვის
                გახდა ცნობილი მომხმარებლის ავტორიზაციისათვის ან
                იდენტიფიცირებისთვის საჭირო ინფორმაცია.
              </p>
              <p>
                Pethub-ი გამორიცხავს როგორც თავის, ასევე მისი ყველა თანამშრომლის
                ნებისმიერ პასუხისმგებლობას წინამდებარე დოკუმენტისა და მოქმედი
                კანონმდებლობის მომხმარებლის მიერ ბრალეული დარღვევის შემთხვევაში.
              </p>
              <p>
                Pethub-ი სასამართლოს მიერ მისი პასუხისმგებლობის დადგენის
                შემთხვევაში შეზღუდულია სადავო პროდუქტის ღირებულების ოდენობით.
              </p>
              <p>
                კომპანია იხსნის სრულ პასუხისმგებლობას ნებისმიერი სახის
                ზიანისათვის ან/და შეფერხებისათვის, რომელიც შესაძლებელია
                გამოწვეულ იქნას მესამე პირების მონაწილეობით - ინტერნეტ
                პროვაიდერების, მობილური კომპანიების და სხვა შეფერხებული
                მუშაობით.
              </p>

              <br />

              <h3>შეზღუდვები ვებგვერდის გამოყენებისას</h3>
              <p>
                ვებგვერდზე არსებული ინფორმაცია შეიძლება შეიცვალოს ან მოიხსნას
                წინასწარი შეტყობინების გარეშე. Pethub-ი მომხმარებელს არ გაუწევს
                იმ სახის მომსახურებას, რომელიც მიაჩნია, რომ ეწინააღმდეგება
                მოქმედ კანონმდებლობას.
              </p>
              <p>
                მომხმარებელს ეკრძალება ვებგვერდის არაკანონიერი მიზნებისთვის
                გამოყენება, მათ შორის, Pethub-ის სისტემებში არასანქცირებული
                შეღწევა, ვებგვერდზე არსებული ინფორმაციის არასათანადოდ გამოყენება
                ან სხვაგვარი ქმედება, რომელმაც შეიძლება პირდაპირი ან ირიბი ზიანი
                მიაყენოს Pethub-ის კანონით დაცულ ინტერესებს.
              </p>

              <br />

              <h3>მარკეტინგი</h3>
              <p>
                მომხმარებლის ინტერესებიდან გამომდინარე, პირდაპირი მარკეტინგის
                გზით, სარეკლამო მასალების , მისთვის საინტერესო შეთავაზების,
                ფასდაკლების, პროდუქციის მიწოდების, სერვისებისა და მომსახურების
                ხარისხის გაუმჯობესების მიზნით Pethub-ი მომხმარებლის თანხმობის
                შემთხვევაში სარგებლობს მისი პერსონალური ხასიათის ინფორმაციით,
                კერძოდ, სახელით და ელ. ფოსტის მისამართით/ტელეფონის ნომრით.
                Pethub-ი უფლებამოსილია პერსონალური ინფორმაცია გაუზიაროს
                საქართველოს მოქმედი კანონმდებლობით გათვალისწინებულ შემთხვევებში,
                ნებისმიერ მესამე პირს.
              </p>
              <p>
                პირდაპირი მარკეტინგის მიზნებისთვის გასცემს თანხმობას აქტიური
                ქმედებით.
              </p>
              <p>
                მომხმარებელს ნებისმიერ დროს აქვს შესაძლებლობა Pethub-ისგან
                გამოიხმოს მის მიერ პირდაპირი მარკეტინგის შესახებ გაცემული
                თანხმობა. თავის მხრივ, Pethub-ი შეწყვეტს მომხმარებლისათვის
                პირდაპირი მარკეტინგის განხორციელებას გამოხმობის თაობაზე
                მოთხოვნის მიღებიდან გონივრულ ვადაში, მაგრამ არაუგვიანეს 7
                (შვიდი) სამუშაო დღისა.
              </p>

              <br />

              <h3>საავტორო უფლებები</h3>
              <p>
                ვებგვერდზე განთავსებული ნებისმიერი ტექსტი, გრაფიკული და
                ანიმაციური გამოსახულებები ექსკლუზიურად ეკუთვნის Pethub-ს.
              </p>
              <p>
                Pethub-ის სასაქონლო ნიშანი რეგისტრირებულია საქპატენტში და
                წარმოადგენს შპს "ფეთჰაბ პლიუსი"-ს საკუთრებას.
              </p>
              <p>
                Pethub-ის სასაქონლო ნიშნის ნებისმიერი სახით გამოყენება,
                Pethub-ის წერილობითი ფორმით გამოხატული წინასწარი თანხმობის
                გარეშე, მკაცრად აკრძალულია. მომხმარებელს არ აქვს უფლება,
                გამოიყენოს Pethub-ის სასაქონლო ნიშანი, ვებგვერდზე განთავსებული
                გრაფიკული გამოსახულებები ნებისმიერ პროდუქტთან ან მომსახურებასთან
                დაკავშირებით, რამაც შეიძლება გამოიწვიოს აღრევა. მომხმარებელს არ
                აქვს უფლება, გამოიყენოს ისინი იმგვარად, რომ დაამციროს ან
                შეურაცხყოფა მიაყენოს Pethub-ის რეპუტაციას. ამგვარი დარღვევის
                შემთხვევაში, Pethub-ი უფლებას იტოვებს, მიმართოს სამართლებრივი
                უფლებების დაცვის საშუალებებს. მომხმარებელი პასუხისმგებელი იქნება
                Pethub-ისათვის მიყენებულ მატერიალურ და არამატერიალურ ზიანზე,
                ზიანი შესაძლოა მოიცავდეს Pethub-ის მიერ ამ ზიანის აღმოფხვრისთვის
                გაწეულ ხარჯებს, მათ შორის, საადვოკატო მომსახურებისა და
                სასამართლოს ხარჯებს.
              </p>
              <p>
                ვებგვერდზე პროდუქციის ყიდვით მომხმარებელზე არ გადადის არანაირი
                საავტორო უფლება.
              </p>
              <p>
                Pethub-ი პატივს სცემს სხვა პირების საავტორო უფლებებს და
                მოუწოდებს მომხმარებელს დაიცვას Pethub-ის საავტორო უფლებები.
              </p>
              <p>
                იმ შემთხვევაში, თუ მომხმარებელი საავტორო უფლებების მესაკუთრეა და
                თვლის, რომ მისი საავტორო უფლების დაცვის ობიექტი რაიმე ფორმით,
                კანონმდებლობის დარღვევით განთავსდა Pethub-ზე, მომხმარებელი უნდა
                დაუკავშირდეს Pethub-ს ელექტრონულ ფოსტაზე და მიუთითოს
                დანიშნულებაში საავტორო უფლების დარღვევა.
              </p>

              <br />

              <h3>მოქმედების ვადა და შეწყვეტა</h3>
              <p>
                წინამდებარე წესები და პირობები ძალაში შედის მომხმარებლის მიერ
                თანხმობის გამოცხადების შემდეგ და მოქმედებს მომხმარებლის
                ანგარიშის გაუქმებამდე.
              </p>
              <p>
                წინამდებარე დოკუმენტის მოქმედება შესაძლებელია შეწყდეს
                საქართველოს კანონმდებლობით ან/და წინამდებარე წესით
                გათვალისწინებულ შემთხვევებში, აგრეთვე მომხმარებლის ინიციატივით
                ნებისმიერ დროს, შეწყვეტის მომენტისათვის Pethub-ის წინაშე
                არსებული ნებისმიერი სახის დავალიანების (ასეთის არსებობის
                შემთხვევაში) სრულად დაფარვის შემდეგ.
              </p>

              <br />

              <h3>მესამე მხარე</h3>
              <p>
                Pethub-ი იხსნის ყოველგვარ პასუხისმგებლობას სხვა ვებგვერდებთან
                მიმართებით, რომლებიც შესაძლებელია გარე ბმულის სახით განთავსებული
                იყოს Pethub-ის ვებგვერდზე. სხვა ვებგვერდის შინაარსი, წესები და
                პირობები, კონფიდენციალურობის პოლიტიკა შესაძლოა განსხვავდებოდეს
                წინამდებარე პოლიტიკისგან. შესაბამისად, Pethub-ი მომხმარებელს
                მოუწოდებს გაეცნოს გარე ბმულის სახით მითითებული ვებგვერდის
                გამოყენების პირობებსა და კონფიდენციალურობის პოლიტიკას.
              </p>

              <br />

              <h3>მარეგულირებელი კანონმდებლობა და დავების გადაწყვეტის წესი</h3>
              <p>
                წინამდებარე წესები და პირობები რეგულირდება და განიმარტება
                საქართველოს მოქმედი კანონმდებლობის შესაბამისად
              </p>
              <p>
                მხარეები თანხმდებიან რომ დავის შემთხვევაში, შეეცდებიან
                მოლაპარაკებით გადაწყდეს წარმოშობილი უთანხმოება. მოლაპარაკებების
                წარმატებით დასრულების შეუძლებლობის შემთხვევაში, მხარეები
                უფლებამოსილნი არიან მიმართონ თბილისის საქალაქო სასამართლოს.
              </p>
              <p>
                წინამდებარე წესებისა და პირობების რომელიმე ნაწილის ბათილად ცნობა
                არ გამოიწვევს სხვა ნაწილის ბათილად ცნობას.
              </p>

              <br />

              <h3 id="#16">ცვლილებები და დამატებები</h3>
              <p>
                Pethub-ი იტოვებს უფლებას, წინამდებარე დოკუმენტში ნებისმიერ დროს,
                ცალმხრივად, ამავე ვებგვერდზე გამოქვეყნების გზით, შეიტანოს
                ცვლილებები ან/და დამატებები მომხმარებლის შემდგომი დამატებითი
                თანხმობის გარეშე. ცვლილებების/დამატებების განხორციელების შემდეგ,
                Pethub-ი მომხმარებლის მიერ ვებგვერდით სარგებლობის გაგრძელებას
                მიიჩნევს მომხმარებლის თანხმობად.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rules;
