import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="rules-parent-container">
        <div className="rules-parent-container-header"></div>
        <div className="rules-inside-container">
          <ul className="table-of-content">
            <li>მიზანი</li>
            <li>ტერმინთა განმარტება</li>
            <li>თანხმობა</li>
            <li>მონაცემთა დამუშავების საფუძველი</li>
            <li>მომხმარებლის პერსონალური მონაცემების დამუშავების მიზანი</li>
            <li>მომხმარებლის უფლებამოსილება</li>
            <li>მომხმარებლის ვალდებულება</li>
            <li>ქოლ-ცენტრი</li>
            <li>Pethub.ge-ის ვებგვერდის გამოყენების პირობები</li>
            <li>არასრულწლოვანი პირი</li>
            <li>ვის ვუზიარებთ მომხმარებლის მონაცემებს</li>
            <li>პირდაპირი მარკეტინგის შესახებ თანხმობა/გამოხმობა</li>
            <li>ლოგ ფაილები და cookies</li>
            <li>მონაცემების მიღება</li>
            <li>რა სახის მონაცემები მუშავდება</li>
            <li>კონფიდენციალური მონაცემების შენახვის ვადა</li>
            <li>უსაფრთხოება</li>
            <li>მესამე მხარე</li>
            <li>ცვლილებები და დამატებები</li>
            <li>Pethub-თან დაკავშირება</li>
          </ul>

          <div className="editor">
            <h2>კონფიდენციალურობის პირობები</h2>
            <div className="read-rules">
              <h2>მიზანი</h2>
              <p>
                ვებგვერდზე www.pethub.ge-ზე ვიზიტის დროს ვალდებული ხართ გაეცნოთ
                ჩვენს კონფიდენციალურობის პოლიტიკას. შესაძლებელია წინამდებარე
                კონფიდენციალურობის პოლიტიკაში განხორციელდეს ცვლილებები და
                დამატებები, შესაბამისად Pethub.ge მოგიწოდებთ, რომ პერიოდულად
                გადაამოწმოთ კონფიდენციალურობის პოლიტიკა.
              </p>

              <p>
                მომხმარებელი, რომელიც Pethub.ge-ს საკუთარი სურვილით მიაწოდებს
                პერსონალური ხასიათის ინფორმაციას, ის ავტომატურად ანიჭებს
                უფლებამოსილებას Pethub.ge-ს გამოიყენოს მომხმარებლის პერსონალური
                მონაცემები შენახვისა და დამუშავების მიზნით.
              </p>

              <p>
                წინამდებარე კონფიდენციალურობის პოლიტიკა განსაზღვრავს
                Pethub.ge-ის მომხმარებელთა პერსონალური ხასიათის მონაცემების
                დამუშავების წესს, მიზნებს, გამოყენებისა და შენახვის პირობებს.
                „პერსონალურ მონაცემთა დაცვის შესახებ“ საქართველოს კანონისა და
                მონაცემთა დაცვის ზოგადი რეგლამენტის (GDPR) საფუძველზე,
                Pethub.ge-ის მიზანია მომხმარებლის პერსონალური მონაცემების დაცვა.
              </p>

              <p>
                წინამდებარე დოკუმენტი (კონფიდენციალურობის პოლიტიკა) წარმოადგენს
                Pethub-ის ვებგვერდით სარგებლობის წესებისა და პირობების განუყოფელ
                ნაწილს, რომელიც არის ორმხრივი მავალდებულებელი დოკუმენტი.
              </p>

              <br />

              <h3>ტერმინთა განმარტება</h3>
              <p>
                წინამდებარე დოკუმენტში გამოყენებულ ტერმინებს აქვს შემდეგი
                მნიშვნელობა:
              </p>
              <ul>
                <li>
                  Pethub (შემდგომში „ჩვენ“, „ჩვენი“, „ვებგვერდი“) - საქართველოს
                  კანონმდებლობის შესაბამისად დაფუძნებული შპს ფეთჰაბ პლიუსი,
                  საიდენტიფიკაციო კოდი 405210374, რეგისტრირებული: ქ. თბილისი,
                  ვაჟა-ფშაველას გამზირი N30ა, ბ. N37.
                </li>
                <li>
                  პერსონალური მონაცემი - ნებისმიერი ინფორმაცია, რომელიც
                  იდენტიფიცირებულ ან იდენტიფიცირებად ფიზიკურ პირს უკავშირდება.
                  ფიზიკური პირი იდენტიფიცირებადია, როდესაც შესაძლებელია მისი
                  იდენტიფიცირება პირდაპირ ან არაპირდაპირ, მათ შორის, სახელით,
                  გვარით, საიდენტიფიკაციო ნომრით, გეოლოკაციის მონაცემებით,
                  ელექტრონული კომუნიკაციის მაიდენტიფიცირებელი მონაცემებით,
                  ფიზიკური, ფიზიოლოგიური, ფსიქიკური, ფსიქოლოგიური, გენეტიკური,
                  ეკონომიკური, კულტურული ან სოციალური მახასიათებლით.
                </li>
                <li>
                  მონაცემთა დამუშავება - მონაცემთა მიმართ შესრულებული ნებისმიერი
                  მოქმედება, მათ შორის, მათი შეგროვება, მოპოვება, მათზე წვდომა,
                  მათი ფოტოგადაღება, ვიდეომონიტორინგი ან/და აუდიომონიტორინგი,
                  ორგანიზება, დაჯგუფება, ურთიერთდაკავშირება, შენახვა, შეცვლა,
                  აღდგენა, გამოთხოვა, გამოყენება, დაბლოკვა, წაშლა ან
                  განადგურება, აგრეთვე მონაცემთა გამჟღავნება მათი გადაცემით,
                  გასაჯაროებით, გავრცელებით ან სხვაგვარად ხელმისაწვდომად
                  გახდომით.
                </li>
                <li>
                  მონაცემთა სუბიექტი (შემდგომში მომხმარებელი) - ნებისმიერი
                  ფიზიკური პირი, რომლის შესახებაც მუშავდება მონაცემები.
                </li>
                <li>
                  მონაცემთა სუბიექტის (მომხმარებლის) თანხმობა − მონაცემთა
                  სუბიექტის მიერ შესაბამისი ინფორმაციის მიღების შემდეგ მის
                  შესახებ მონაცემთა კონკრეტული მიზნით დამუშავებაზე აქტიური
                  მოქმედებით, წერილობით (მათ შორის, ელექტრონულად) ან ზეპირად,
                  თავისუფლად და მკაფიოდ გამოხატული ნება.
                </li>
                <li>
                  დამუშავებისთვის პასუხისმგებელი პირი − ფიზიკური პირი, იურიდიული
                  პირი ან საჯარო დაწესებულება, რომელიც ინდივიდუალურად ან
                  სხვებთან ერთად განსაზღვრავს მონაცემთა დამუშავების მიზნებსა და
                  საშუალებებს, უშუალოდ ან დამუშავებაზე უფლებამოსილი პირის
                  მეშვეობით ახორციელებს მონაცემთა დამუშავებას.
                </li>
                <li>
                  დამუშავებაზე უფლებამოსილი პირი − ფიზიკური პირი,იურიდიული პირი
                  ან საჯარო დაწესებულება, რომელიც მონაცემებს ამუშავებს
                  დამუშავებისთვის პასუხისმგებელი პირისთვის ან მისი სახელით.
                  დამუშავებაზე უფლებამოსილ პირად არ მიიჩნევა დამუშავებისთვის
                  პასუხისმგებელ პირთან შრომით ურთიერთობაში მყოფი ფიზიკური პირი.
                </li>
                <li>
                  მესამე პირი - ფიზიკური პირი, იურიდიული პირი ან საჯარო
                  დაწესებულება, გარდა მონაცემთა სუბიექტისა, პერსონალურ მონაცემთა
                  დაცვის სამსახურისა, დამუშავებისთვის პასუხისმგებელი პირისა,
                  დამუშავებაზე უფლებამოსილი პირისა, სპეციალური წარმომადგენლისა
                  და იმ პირისა, რომელიც უფლებამოსილია დაამუშაოს მონაცემები
                  დამუშავებისთვის პასუხისმგებელი პირის ან დამუშავებაზე
                  უფლებამოსილი პირის პირდაპირი დავალებით.
                </li>
                <li>
                  პირდაპირი მარკეტინგი - ტელეფონის, ფოსტის, ელექტრონული ფოსტის
                  ან სხვა ელექტრონული საშუალებით მონაცემთა სუბიექტისთვის
                  ინფორმაციის პირდაპირი და უშუალო მიწოდება ფიზიკური პირის ან/და
                  იურიდიული პირის, საქონლის, იდეის, მომსახურების, სამუშაოს ან/და
                  წამოწყების, აგრეთვე საიმიჯო და სოციალური თემატიკისადმი
                  ინტერესის ფორმირების, შენარჩუნების, რეალიზაციის ან/და
                  მხარდაჭერის მიზნით.
                </li>
              </ul>

              <br />

              <h3>თანხმობა</h3>
              <p>
                Pethub-ის ვებგვერდით სარგებლობა მიიჩნევა მომხმარებლის მიერ
                კონფიდენციალურობის პოლიტიკის თაობაზე თანხმობად. შესაბამისად,
                მომხმარებელი Pethub-ს ანიჭებს უფლებას დაამუშაოს, შეინახოს და
                გამოიყენოს მომხმარებლის მიერ მიწოდებული პერსონალური ხასიათის
                ინფორმაცია.
              </p>
              <p>
                აღნიშნული ინფორმაცია Pethub-ის მიერ გამოყენებული იქნება
                მომსახურების გაწევის მიზნებიდან გამომდინარე მოქმედი
                კანონმდებლობის გათვალისწინებით.
              </p>

              <br />

              <h3>მონაცემთა დამუშავების საფუძველი</h3>
              <p>Pethub მომხმარებლის პერსონალურ მონაცემებს ამუშავებს:</p>
              <ul>
                <li>მომხმარებლის თანხმობის საფუძველზე.</li>
                <li>
                  მისთვის კანონმდებლობით დაკისრებული მოვალეობების შესასრულებლად.
                </li>
                <li>
                  მონაცემთა დამუშავება აუცილებელია მომხმარებლისათვის
                  მომსახურების გასაწევად.
                </li>
                <li>
                  კანონის თანახმად, მონაცემი საჯაროდ ხელმისაწვდომია ან მონაცემთა
                  სუბიექტმა იგი საჯაროდ ხელმისაწვდომი გახადა.
                </li>
              </ul>

              <br />

              <h3>მომხმარებლის პერსონალური მონაცემების დამუშავების მიზანი</h3>
              <ul>
                <li>Pethub-ის მომსახურების ხარისხის გაუმჯობესება;</li>
                <li>
                  Pethub-ის კანონისმიერი ვალდებულებების ჯეროვნად შესრულება;
                </li>
                <li>მომხმარებლის მიერ წაყენებული პრეტენზიების აღმოფხვრა;</li>
                <li>
                  მომხმარებლის მიერ ვებგვერდზე გამოვლენილი აქტივობების
                  მონიტორინგი;
                </li>
                <li>
                  Pethub-ზე განახლებული მონაცემების მომხმარებლებისათვის
                  შეტყობინება;
                </li>
                <li>მომხმარებელთან ეფექტური კომუნიკაცია;</li>
                <li>მომხმარებლის მიერ უკუკავშირის დამყარება;</li>
                <li>Pethub-ის მიერ დანერგილი სიახლეების დატესტვა;</li>
                <li>ვებგვერდზე ახალი პროდუქტებისა და სიახლეების დანერგვა;</li>
                <li>
                  მომხმარებლების იმ ჯგუფთა განსაზღვრა, რომელთათვის უნდა
                  განხორციელდეს სიახლეების შეთავაზება;
                </li>
                <li>მარკეტინგული და სხვა სახის აქტივობების განხორციელება;</li>
                <li>
                  მომხმარებლის მიერ განხორციელებული შესყიდვების თაობაზე
                  ინფორმაციის მიწოდება;
                </li>
                <li>
                  მომხმარებლის მიერ ფულადი ვალდებულების შესრულების თაობაზე
                  უფლებამოსილი მესამე პირისგან ინფორმაციის მიღება;
                </li>
                <li>
                  Pethub-ის სისტემის არამართლზომიერი გამოყენების პრევენცია;
                </li>
                <li>
                  საქართველოს სისხლის სამართლის კოდექსით გათვალისწინებული
                  დანაშაულის პრევენცია;
                </li>
                <li>სახელმწიფო კომპეტენტური ორგანოებისათვის მიწოდება;</li>
                <li>კანონიერი ინტერესების დაცვა;</li>
                <li>
                  Pethub-ისთვის პერსონალურ მონაცემთა დაცვის შესახებ საქართველოს
                  კანონით მინიჭებული სხვა ლეგიტიმური მიზანი.
                </li>
              </ul>

              <br />

              <h3>მომხმარებლის უფლებამოსილება</h3>
              <p>
                მომხმარებელს უფლება აქვს Pethub-ს მოსთხოვოს მონაცემთა
                დამუშავების შესახებ შემდეგი ინფორმაცია:
              </p>
              <ul>
                <li>
                  მომხმარებლის შესახებ რომელი მონაცემები მუშავდება Pethub-ის
                  მიერ;
                </li>
                <li>მონაცემთა დამუშავების მიზანი;</li>
                <li>მონაცემთა დამუშავების სამართლებრივი საფუძველი;</li>
                <li>რა გზით შეგროვდა მონაცემები;</li>
                <li>
                  ვისზე გაიცა მომხმარებლის შესახებ მონაცემები, მონაცემთა გაცემის
                  საფუძველი და მიზანი;
                </li>
              </ul>
              <p>
                მომხმარებლის მიერ მოთხოვნის შემთხვევაში Pethub ვალდებულია
                გაასწოროს, განაახლოს, დაამატოს, დაბლოკოს, წაშალოს ან გაანადგუროს
                მონაცემები, თუ ისინი არასრულია, არაზუსტია, არ არის განახლებული
                ან თუ მათი შეგროვება და დამუშავება განხორციელდა კანონის
                მოთხოვნათა დარღვევით.
              </p>
              <p>
                მომხმარებელი უფლებამოსილია პერსონალურ მონაცემთა დაცვის შესახებ
                საქართველოს კანონით გათვალისწინებული უფლებების დარღვევის
                შემთხვევაში მიმართოს პერსონალურ მონაცემთა დაცვის სამსახურს ან
                სასამართლოს.
              </p>
              <p>
                მომხმარებელს უფლება აქვს, ნებისმიერ დროს განაცხადოს უარი მის
                მიერ Pethub-ისათვის მიცემულ თანხმობაზე და მოითხოვოს მონაცემთა
                დამუშავების შეწყვეტა ან/და დამუშავებული მონაცემების განადგურება.
                ასეთ შემთხვევაში, Pethub თავის მხრივ, კანონმდებლობით
                გათვალისწინებულ ვადაში გააუქმებს მომხმარებლის პირად ანგარიშს და
                შეწყვეტს მონაცემთა დამუშავებას ან/და გაანადგურებს დამუშავებულ
                მონაცემებს.
              </p>

              <br />

              <h3>მომხმარებლის ვალდებულება</h3>
              <p>
                მომხმარებელი ვალდებულია Pethub-ს მის შესახებ მიაწოდოს სრულყოფილი
                და სწორი ინფორმაცია შემდგომში მომსახურების მიღების მიზნით.
                აღნიშნული ვალდებულება ასევე ვრცელდება მომხმარებლის მიერ
                მიწოდებული ინფორმაციის ცვლილებებზე.
              </p>

              <br />

              <h3>ქოლ-ცენტრი</h3>
              <p>
                Pethub-ის მომსახურების ხარისხის გაუმჯობესების,
                პრობლემის/ხარვეზის აღმოფხვრის, უკუკავშირისა და უსაფრთხოების
                მიზნით ხორციელდება შემომავალი და გამავალი ზარების ჩაწერა,
                შენახვა და დამუშავება.
              </p>

              <br />

              <h3>Pethub-ის ვებგვერდის გამოყენების პირობები</h3>
              <p>
                მომხმარებლის მიერ Pethub-ის ვებგვერდის გამოყენებით მომხმარებელი
                აცხადებს თანხმობას Pethub-ის მიერ მისი პერსონალური მონაცემებისა
                და მზა ჩანაწერების (Cookies) შენახვასა და დამუშავებაზე.
              </p>
              <p>
                მომხმარებელი ვალდებულია Pethub-ს მიაწოდოს სრული და სწორი
                ინფორმაცია, ცვლილების შემთხვევაში განაახლოს მის მიერ მიწოდებული
                ინფორმაცია.
              </p>

              <br />

              <h3>არასრულწლოვანი პირი</h3>
              <p>
                Pethub მოკლებულია შესაძლებლობას გადაამოწმოს მომხმარებლის მიერ
                მითითებული ინფორმაციის უტყუარობა და სისწორე, მათ შორის,
                მომხმარებლის ასაკი. თუ ცნობილი გახდება, რომ ინფორმაციის
                მომწოდებელი პირი არ არის სრულწლოვანი, Pethub უფლებამოსილია არ
                გაითვალისწინოს არასრულწლოვანი პირის მიერ მოწოდებული ინფორმაცია.
                თუ თქვენთვის ცნობილი გახდა, რომ Pethub-ზე რეგისტრირებული პირი არ
                არის სრულწლოვანი და არ აქვს კანონიერი წარმომადგენლის თანხმობა
                ვებგვერდით სარგებლობასთან დაკავშირებით, გთხოვთ გვაცნობოთ
                აღნიშნულის თაობაზე.
              </p>

              <br />

              <h3>ვის ვუზიარებთ მომხმარებლის მონაცემებს</h3>
              <p>
                პერსონალური მონაცემების გაზიარება ხორციელდება პარტნიორი
                საკურიერო მომსახურების გამწევი კომპანიებისათვის, რომლებიც
                უზრუნველყოფენ მომხმარებლისათვის შეძენილი პროდუქციის მიწოდებას
                მის მიერ მითითებულ მისამართზე. საკურიერო მომსახურების გამწევ
                პარტნიორ კომპანიებს მომხმარებლის შესახებ გადაეცემა შემდეგი სახის
                ინფორმაცია: შეკვეთის დეტალები, სახელი, გვარი, მისამართი და
                საკონტაქტო ნომერი. პარტნიორი საკურიერო კომპანიებისათვის
                მიწოდებული ინფორმაცია გამოყენებული იქნება მხოლოდ შეძენილი
                პროდუქციის მიწოდების მიზნით.
              </p>
              <p>
                მომხმარებლის პერსონალური მონაცემების გაზიარება შესაძლებელია
                საჭირო გახდეს საქართველოს მოქმედი კანონმდებლობით გათვალისწინებულ
                შემთხვევებში, დანაშაულის დადგენის, გამოძიების ან დანაშაულის
                აღკვეთის მიზნით.
              </p>
              <p>
                მომხმარებლის ინტერესებიდან გამომდინარე, პირდაპირი მარკეტინგის
                გზით, სარეკლამო მასალების მოწოდების, მომხმარებლისათვის
                საინტერესო მომსახურების, პროდუქციის, სერვისებისა და მომსახურების
                ხარისხის გაუმჯობესების მიზნით Pethub-ს მომხმარებლის თანხმობის
                შემთხვევაში შეუძლია ისარგებლოს მომხმარებლის პერსონალური
                ინფორმაციით, კერძოდ, სახელით და ელ. ფოსტის მისამართით/ტელეფონის
                ნომრით.
              </p>
              <p>
                Pethub უფლებამოსილია პერსონალური ინფორმაცია გაუზიაროს
                საქართველოს მოქმედი კანონმდებლობით გათვალისწინებულ შემთხვევებში,
                ნებისმიერ მესამე პირს.
              </p>

              <br />

              <h3>პირდაპირი მარკეტინგის შესახებ თანხმობა/გამოხმობა</h3>
              <p>
                პირდაპირი მარკეტინგის მიზნებისთვის მომხმარებელი გასცემს
                თანხმობას აქტიური ქმედებით.
              </p>
              <p>
                მომხმარებელს ნებისმიერ დროს აქვს შესაძლებლობა Pethub-სგან
                გამოიხმოს მის მიერ პირდაპირი მარკეტინგის შესახებ გაცემული
                თანხმობა. თავის მხრივ, Pethub შეწყვეტს მომხმარებლისთვის
                პირდაპირი მარკეტინგის განხორციელებას გამოხმობის თაობაზე
                მოთხოვნის მიღებიდან გონივრულ ვადაში, მაგრამ არაუგვიანეს 7
                (შვიდი) სამუშაო დღისა.
              </p>
              <p>
                მომხმარებლის მიერ პირდაპირი მარკეტინგის შესახებ გაცემული
                თანხმობის გამოხმობა ხდება Pethub-ს ვებგვერდზე პირად ანგარიშში
                შემდეგი გზით:
              </p>
              <p>
                მომხმარებლის პირად ანგარიშში მითითებულია ღილაკი „switch on/off“
                რომლის მეშვეობით მომხმარებელი შეძლებს განაცხადოს თანხმობა/უარი
                პირდაპირი მარკეტინგის მიზნებისთვის ინფორმაციის დამუშავებაზე,
                სიახლეებისა და შეთავაზებების შესახებ SMS&Email შეტყობინებების
                მიღებაზე.
              </p>
              <p>
                Pethub ვალდებულია მომხმარებლის მიერ თანხმობის გაცემისა და
                თანხმობის გამოხმობის დრო და ფაქტი აღრიცხოს და შეინახოს პირდაპირი
                მარკეტინგის განხორციელების ვადით და პირდაპირი მარკეტინგის
                განხორციელების შეწყვეტიდან 1 (ერთი) წლის განმავლობაში.
              </p>
              <p>
                მომხმარებლის მიერ პირდაპირი მარკეტინგის შეწყვეტის თაობაზე
                მოთხოვნა არ ავალდებულებს Pethub-ს შეწყვიტოს ინფორმაციის მიწოდება
                განახლებებისა და ცვლილებების შესახებ.
              </p>

              <br />

              <h3>ლოგ ფაილები და cookies</h3>
              <p>
                Pethub სარგებლობს ლოგ ფაილების სტანდარტული პროცედურით. სხვა
                ვებგვერდების მსგავსად, Pethub იყენებს Cookies. ლოგ ფაილები და
                Cookies გამოიყენება იმისთვის რომ ვებგვერდმა შეინახოს ინფორმაცია
                მომხმარებლების ქცევის შესახებ და გააუმჯობესოს მომსახურების
                ხარისხი. მათი მეშვეობით გენერირდება და გროვდება შემდეგი
                ინფორმაცია:
              </p>
              <ul>
                <li>IP მისამართი</li>
                <li>მოწყობილობის ტიპი</li>
                <li>ბრაუზერი</li>
                <li>ვებგვერდის ვიზიტის დრო, თარიღი და ხანგრძლივობა</li>
                <li>
                  რომელი პლატფორმიდან გადმომისამართდა მომხმარებელი Pethub-ის
                  ვებგვერდზე
                </li>
                <li>
                  ინფორმაცია Pethub-ის ვებგვერდზე განხორციელებული ქმედებების
                  შესახებ - რა პროდუქტები მოინახულა მომხმარებელმა
                </li>
              </ul>
              <p>
                ამ ინფორმაციით არ ხდება კონკრეტული მომხმარებლის იდენტიფიცირება
                და წარმოადგენს ანალიტიკურ მონაცემებს.'
              </p>

              <p>ამგვარი ინფორმაციის დამუშავების მიზანია:</p>
              <ul>
                <li>გავაანალიზოთ ტრენდები</li>
                <li>Pethub-მა მართოს ვებგვერდი</li>
                <li>აწარმოოს სტატისტიკა</li>
                <li>
                  თვალი ადევნოს მომხმარებლების ქმედებებს ვებგვერდზე გამოცდილების
                  შექმნის მიზნით
                </li>
                <li>
                  შეიმუშავოს და განახორციელოს ეფექტური მარკეტინგული აქტივობები
                </li>
                <li>გასცეს რჩევები და რეკომენდაციები</li>
              </ul>
              <p>
                ვებგვერდის გამოყენების დაწყებისას მომხმარებელს აქვს საშუალება
                დაეთანხმოთ cookies.
              </p>
              <p>
                Cookie-ს პარამეტრების ცვლილება შესაძლებელია ბრაუზერის
                პარამეტრების შეცვლით.
              </p>

              <br />

              <h3>მონაცემების მიღება</h3>
              <p>Pethub ინფორმაციას იღებს შემდეგი წყაროებიდან:</p>
              <ul>
                <li>
                  როდესაც მომხმარებელი ავსებს ვებგვერდის სარეგისტრაციო ფორმას
                </li>
                <li>
                  როდესაც კომუნიკაცია ხდება ტელეფონის ან ელექტრონული ფოსტის
                  მეშვეობით
                </li>
                <li>
                  როდესაც მომხმარებელი იყენებს Pethub-ის ვებგვერდს და ჩატს
                </li>
              </ul>

              <br />

              <h3>რა სახის მონაცემები მუშავდება</h3>
              <p>
                ვებგვერდზე რეგისტრაციისას მომხმარებელი უთითებს შემდეგ
                ინფორმაციას:
              </p>
              <ul>
                <li>მობილურის ნომერი</li>
                <li>ელექტრონული ფოსტა</li>
                <li>მისამართი</li>
                <li>სახელი და გვარი</li>
                <li>ონლაინ მაღაზიაში ავტორიზაციისთვის ფასვორდი</li>
              </ul>
              <p>
                რეგისტრაციისთვის აუცილებელია აღნიშნული ინფორმაციის მითითება,
                რომლის გარეშე მომხმარებელი ვერ განახორციელებს ვებგვერდზე
                რეგისტრაციას.
              </p>
              <p>
                პროდუქციის შერჩევის შემდგომ მომხმარებელი ანგარიშსწორების მიზნით
                გადამისამართდება ბანკის ანგარიშსწორების გვერდზე, სადაც უთითებს
                მისი ბარათის მონაცემებს და ადასტურებს გადარიცხვას. Pethub-ს არ
                აქვს წვდომა მომხმარებლის საბანკო ბარათის მონაცემებზე. Pethub
                იღებს ინფორმაციას მომხმარებლის მიერ ანგარიშსწორების შესახებ.
              </p>

              <br />

              <h3>კონფიდენციალური მონაცემების შენახვის ვადა</h3>
              <p>
                Pethub პასუხისმგებლობას იღებს მომხმარებლის პერსონალური
                მონაცემების საიდუმლოებასა და უსაფრთხოებაზე.
              </p>
              <p>
                მომხმარებლის შესახებ კონფიდენციალურ ინფორმაციას Pethub ინახავს
                კანონმდებლობით და წინამდებარე პოლიტიკით გათვალისწინებული მიზნის
                მიღწევისათვის საჭირო ვადით.
              </p>

              <br />

              <h3>უსაფრთხოება</h3>
              <p>
                Pethub-ისთვის მნიშვნელოვანია მომხმარებლის მიერ მიწოდებული
                კონფიდენციალური ხასიათის ინფორმაციის დაცვა. მომხმარებლის პირადი
                ანგარიში ხელმისაწვდომია მხოლოდ მის მიერ შერჩეული მომხმარებლის
                სახელისა და ფასვორდის საშუალებით. Pethub გთხოვთ, აღნიშნული
                ინფორმაცია არ გაანდოთ მესამე პირებს. წინააღმდეგ შემთხვევაში
                Pethub იხსნის სრულ პასუხისმგებლობას.
              </p>
              <p>
                Pethub მიმართავს ყველა ზომას მომხმარებლის კონფიდენციალური
                ინფორმაციის დაცვის მიზნით, თუმცა არ იღებს პასუხისმგებლობას და არ
                იძლევა გარანტიას მესამე პირების ქმედებებზე, რომლებიც
                ახორციელებენ კანონსაწინააღმდეგო შეღწევას Pethub-ის სერვერებზე.
              </p>

              <h3>მესამე მხარე</h3>
              <p>
                Pethub იხსნის ყოველგვარ პასუხისმგებლობას სხვა ვებგვერდებთან
                მიმართებით, რომლებიც შესაძლებელია გარე ბმულის სახით განთავსებული
                იყოს Pethub-ის ვებგვერდზე. სხვა ვებგვერდის კონფიდენციალურობის
                პოლიტიკა შესაძლოა განსხვავდებოდეს წინამდებარე პოლიტიკისგან.
                შესაბამისად, Pethub მოგიწოდებთ გაეცნოთ გარე ბმულის სახით
                მითითებული ვებგვერდის გამოყენების პირობებსა და
                კონფიდენციალურობის პოლიტიკას.
              </p>

              <br />

              <h3>ცვლილებები და დამატებები</h3>
              <p>
                შესაძლებელია წინამდებარე დოკუმენტში განხორციელდეს ცვლილებები და
                დამატებები. ცვლილებების ან დამატებების შეტანის შემთხვევაში
                მომხმარებელი მიიღებს პირად შეტყობინებას ან გამოქვეყნდება
                ვებგვერდზე.
              </p>
              <p>
                ცვლილებები და დამატებები ძალაში შედის Pethub-ის მიერ
                მომხმარებლისათვის პირადი შეტყობინების გაგზავნის ან ვებგვერდზე
                გამოქვეყნების შემდეგ. ცვლილებებისა და დამატებების ძალაში შესვლის
                შემდგომ, მომხმარებლის მიერ ვებგვერდით სარგებლობა განხილული
                იქნება როგორც შეცვლილ პირობებზე თანხმობა.
              </p>

              <br />

              <h3>Pethub-თან დაკავშირება</h3>
              <p>
                კონფიდენციალურობის პოლიტიკასთან დაკავშირებული კითხვებისა და
                დამატებითი ინფორმაციის მიღების მიზნით დაგვიკავშირდით
                info@pethub.ge.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
